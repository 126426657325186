import moment from "moment"
import { simpleFetch, acknowledgeErrors, agendaStateChange } from "./index"
import { stringifyQuery } from "../helpers/qs"
import { fetchCustomerMessages } from "./acCustomers"
import { refetchAgendaLines } from "./acAgenda"

export const WORKSHOP_STATE_CHANGE = "WORKSHOP_STATE_CHANGE"
export const workshopStateChange = changes => ({ type: WORKSHOP_STATE_CHANGE, changes })

export const WORKSHOP_SETTINGS_STATE_CHANGE = "WORKSHOP_SETTINGS_STATE_CHANGE"
export const workshopSettingsStateChange = changes => ({
	type: WORKSHOP_SETTINGS_STATE_CHANGE,
	changes,
})

export const WORKSHOP_ORDERS_ISSUES_STATE_CHANGE = "WORKSHOP_ORDERS_ISSUES_STATE_CHANGE"
export const workshopOrdersIssuesStateChange = changes => ({
	type: WORKSHOP_ORDERS_ISSUES_STATE_CHANGE,
	changes,
})

export const WORKSHOP_HOUR_MODAL_STATE_CHANGE = "WORKSHOP_HOUR_MODAL_STATE_CHANGE"
export const workshopHourModalStateChange = changes => ({
	type: WORKSHOP_HOUR_MODAL_STATE_CHANGE,
	changes,
})

export const MISSING_LAUNDRY_ORDER_MODAL_STATE_CHANGE = "MISSING_LAUNDRY_ORDER_MODAL_STATE_CHANGE"
export const missingLaundryOrderModalStateChange = changes => ({
	type: MISSING_LAUNDRY_ORDER_MODAL_STATE_CHANGE,
	changes,
})

export const RESET_MISSING_LAUNDRY_ORDER_MODAL = "RESET_MISSING_LAUNDRY_ORDER_MODAL"
export const resetMissingLaundryOrderModal = () => ({ type: RESET_MISSING_LAUNDRY_ORDER_MODAL })

export const RESET_WORKSHOP_HOUR_MODAL = "RESET_WORKSHOP_HOUR_MODAL"
export const resetWorkshopHourModal = () => ({ type: RESET_WORKSHOP_HOUR_MODAL })

export const WORKSHOP_ORDERS_FILTERS_STATE_CHANGE = "WORKSHOP_ORDERS_FILTERS_STATE_CHANGE"
export const workshopOrdersFiltersStateChange = changes => ({
	type: WORKSHOP_ORDERS_FILTERS_STATE_CHANGE,
	changes,
})

export const WORKSHOP_ORDER_DETAILS_STATE_CHANGE = "WORKSHOP_ORDER_DETAILS_STATE_CHANGE"
export const workshopOrderDetailsStateChange = changes => ({
	type: WORKSHOP_ORDER_DETAILS_STATE_CHANGE,
	changes,
})

export const AVAILABLE_ORDER_ITEM_CREATOR_STATE_CHANGE = "AVAILABLE_ORDER_ITEM_CREATOR_STATE_CHANGE"
export const availableOrderItemCreatorStateChange = changes => ({
	type: AVAILABLE_ORDER_ITEM_CREATOR_STATE_CHANGE,
	changes,
})

export const DELETED_AVAILABLE_ORDER_ITEM = "DELETED_AVAILABLE_ORDER_ITEM"
export const deletedAvailableOrderItem = id => ({
	type: DELETED_AVAILABLE_ORDER_ITEM,
	id,
})

export const NEW_WORKSHOP_ORDER_STATE_CHANGE = "NEW_WORKSHOP_ORDER_STATE_CHANGE"
export const newWorkshopOrderStateChange = changes => ({
	type: NEW_WORKSHOP_ORDER_STATE_CHANGE,
	changes,
})

export const RESET_NEW_WORKSHOP_ORDER = "RESET_NEW_WORKSHOP_ORDER"
export const resetNewWorkshopOrderState = () => ({ type: RESET_NEW_WORKSHOP_ORDER })

export const RESET_UPDATE_WORKSHOP_ORDER = "RESET_UPDATE_WORKSHOP_ORDER"
export const resetUpdateWorkshopOrderState = changes => ({
	type: RESET_UPDATE_WORKSHOP_ORDER,
	changes,
})

export const WORKSHOP_COMMAND_CENTER_STATE_CHANGE = "WORKSHOP_COMMAND_CENTER_STATE_CHANGE"
export const workshopCommandCenterStateChange = changes => ({
	type: WORKSHOP_COMMAND_CENTER_STATE_CHANGE,
	changes,
})

export const WORKSHOP_ORDERS_STATE_CHANGE = "WORKSHOP_ORDERS_STATE_CHANGE"
export const workshopOrdersStateChange = changes => ({
	type: WORKSHOP_ORDERS_STATE_CHANGE,
	changes,
})

export const WORKSHOP_DAY_ORDER_STATE_CHANGE = "WORKSHOP_DAY_ORDER_STATE_CHANGE"
export const workshopDayOrderStateChange = changes => ({
	type: WORKSHOP_DAY_ORDER_STATE_CHANGE,
	changes,
})

export const WORKSHOP_ORDERS_TO_RETRIEVE_STATE_CHANGE = "WORKSHOP_ORDERS_TO_RETRIEVE_STATE_CHANGE"
export const workshopOrdersToRetrieveStateChange = changes => ({
	type: WORKSHOP_ORDERS_TO_RETRIEVE_STATE_CHANGE,
	changes,
})

export const UPDATE_WORKSHOP_ORDER_MODAL_STATE_CHANGE = "UPDATE_WORKSHOP_ORDER_MODAL_STATE_CHANGE"
export const updateWorkshopOrderModalStateChange = changes => ({
	type: UPDATE_WORKSHOP_ORDER_MODAL_STATE_CHANGE,
	changes,
})

export const RESET_UPDATE_WORKSHOP_ORDER_MODAL_STATE = "RESET_UPDATE_WORKSHOP_ORDER_MODAL_STATE"
export const resetUpdateWorkshopOrderModalState = changes => ({
	type: RESET_UPDATE_WORKSHOP_ORDER_MODAL_STATE,
	changes,
})

export const FINISH_WORKSHOP_ORDER_MODAL_STATE_CHANGE = "FINISH_WORKSHOP_ORDER_MODAL_STATE_CHANGE"
export const finishWorkshopOrderModalStateChange = changes => ({
	type: FINISH_WORKSHOP_ORDER_MODAL_STATE_CHANGE,
	changes,
})

export const RESET_FINISH_WORKSHOP_ORDER_MODAL_STATE = "RESET_FINISH_WORKSHOP_ORDER_MODAL_STATE"
export const resetFinishWorkshopOrderModalState = changes => ({
	type: RESET_FINISH_WORKSHOP_ORDER_MODAL_STATE,
	changes,
})

export const EDIT_WORKSHOP_ORDER_LIST = "EDIT_WORKSHOP_ORDER_LIST"
export const editWorkshopOrderList = newList => ({ type: EDIT_WORKSHOP_ORDER_LIST, newList })

const validateNewOrderState = newOrderState => {
	let {
		chosenOrderItems,
		dropOffDate,
		pickUpDate,
		chosenCustomer,
		currentQuote,
		orderCanBeDoneInTime,
		workshopId,
		svType,
	} = newOrderState
	let readyForStepTwo = chosenCustomer.id && svType && workshopId
	let readyForStepThree =
		readyForStepTwo && chosenOrderItems.length > 0 && currentQuote.details.length > 0
	let readyForSubmit = readyForStepThree && dropOffDate && pickUpDate && orderCanBeDoneInTime
	return [readyForStepTwo, readyForStepThree, readyForSubmit]
}

export const LAUNDRY_DAY_REPORT = "LAUNDRY_DAY_REPORT"
export const LAUNDRY_DAY_REPORT_REQUEST = "LAUNDRY_DAY_REPORT_REQUEST"
export const LAUNDRY_DAY_REPORT_SUCCESS = "LAUNDRY_DAY_REPORT_SUCCESS"
export const LAUNDRY_DAY_REPORT_FAILURE = "LAUNDRY_DAY_REPORT_FAILURE"
export const fetchDayReport = () => async (dispatch, getState) => {
	let {
		redComponents: {
			workshopComponent: { workshopId },
		},
	} = getState()
	if (!workshopId) {
		return
	}
	let url =
		"/front/affiliates/laundry/laundry_orders/day_report" +
		stringifyQuery({
			workshop_id: workshopId,
		})
	let init = { method: "GET" }
	dispatch(simpleFetch(url, init, LAUNDRY_DAY_REPORT))
}

export const MAX_WORKSHOP_SHIFT = "MAX_WORKSHOP_SHIFT"
export const MAX_WORKSHOP_SHIFT_REQUEST = "MAX_WORKSHOP_SHIFT_REQUEST"
export const MAX_WORKSHOP_SHIFT_SUCCESS = "MAX_WORKSHOP_SHIFT_SUCCESS"
export const MAX_WORKSHOP_SHIFT_FAILURE = "MAX_WORKSHOP_SHIFT_FAILURE"
export const fetchMaxShift = () => async (dispatch, getState) => {
	let {
		redComponents: {
			workshopComponent: { workshopId },
		},
	} = getState()
	if (!workshopId) {
		return
	}
	let url =
		"/front/affiliates/laundry/laundry_orders/max_whs_shift_length" +
		stringifyQuery({
			workshop_id: workshopId,
		})
	let init = { method: "GET" }
	dispatch(simpleFetch(url, init, MAX_WORKSHOP_SHIFT))
}

export const FETCH_AVAILABLE_ORDER_ITEMS_LIST = "FETCH_AVAILABLE_ORDER_ITEMS_LIST"
export const FETCH_AVAILABLE_ORDER_ITEMS_LIST_REQUEST = "FETCH_AVAILABLE_ORDER_ITEMS_LIST_REQUEST"
export const FETCH_AVAILABLE_ORDER_ITEMS_LIST_SUCCESS = "FETCH_AVAILABLE_ORDER_ITEMS_LIST_SUCCESS"
export const FETCH_AVAILABLE_ORDER_ITEMS_LIST_FAILURE = "FETCH_AVAILABLE_ORDER_ITEMS_LIST_FAILURE"
export const fetchAvailableOrderItemsList = () => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/laundry/available_laundry_order_items"
	dispatch(simpleFetch(url, init, FETCH_AVAILABLE_ORDER_ITEMS_LIST))
}

export const FETCH_WORKSHOP_ORDER = "FETCH_WORKSHOP_ORDER"
export const FETCH_WORKSHOP_ORDER_REQUEST = "FETCH_WORKSHOP_ORDER_REQUEST"
export const FETCH_WORKSHOP_ORDER_SUCCESS = "FETCH_WORKSHOP_ORDER_SUCCESS"
export const FETCH_WORKSHOP_ORDER_FAILURE = "FETCH_WORKSHOP_ORDER_FAILURE"
export const fetchWorkshopOrder = id => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/laundry/laundry_orders/" + id
	return dispatch(simpleFetch(url, init, "FETCH_WORKSHOP_ORDER"))
}

export const EDIT_WORKSHOP_ORDER = "EDIT_WORKSHOP_ORDER"
export const editWorkshopOrder = data => ({ type: EDIT_WORKSHOP_ORDER, data: data })

export const denormalizeWorkshopOrder = wo => ({
	id: wo.id,
	workshop_id: Number(wo.workshopId),
	drop_off_date: wo.dropOffDate.format("YYYY-MM-DD"),
	priority: wo.priority,
	sv_type: wo.svType,
	execution_date: wo.executionDate.format("YYYY-MM-DD"),
	scheduled_pick_up_date: wo.scheduledPickupDate.format("YYYY-MM-DD"),
	effective_pick_up_date: wo.effectivePickUpDate.isValid()
		? wo.effectivePickUpDate.format("YYYY-MM-DD")
		: null,
	status: wo.status,
	start_execution_datetime: wo.startTime ? wo.startTime.format() : null,
	end_execution_datetime: wo.endTime ? wo.endTime.format() : null,
	executed_duration_minutes: wo.executedDurationMinutes,
	workshop_order_duration_choice: wo.workshopOrderBillingBasis,
	payment_method: wo.paymentMethod,
	cash_received: wo.cashReceived || 0,
	items: wo.items.map(entry => ({ av_item_id: entry.avItemID, quantity: entry.quantity })),
	billable_hours_override: wo.billableHoursOverride,
})

export const UPDATE_WORKSHOP_ORDER = "UPDATE_WORKSHOP_ORDER"
export const UPDATE_WORKSHOP_ORDER_REQUEST = "UPDATE_WORKSHOP_ORDER_REQUEST"
export const UPDATE_WORKSHOP_ORDER_SUCCESS = "UPDATE_WORKSHOP_ORDER_SUCCESS"
export const UPDATE_WORKSHOP_ORDER_FAILURE = "UPDATE_WORKSHOP_ORDER_FAILURE"
export const CANCEL_WORKSHOP_ORDER = "CANCEL_WORKSHOP_ORDER"
export const CANCEL_WORKSHOP_ORDER_REQUEST = "CANCEL_WORKSHOP_ORDER_REQUEST"
export const CANCEL_WORKSHOP_ORDER_SUCCESS = "CANCEL_WORKSHOP_ORDER_SUCCESS"
export const CANCEL_WORKSHOP_ORDER_FAILURE = "CANCEL_WORKSHOP_ORDER_FAILURE"
export const submitUpdateWorkshopOrder = (newOrder, options = {}) => (dispatch, getState) => {
	let {
		redComponents: {
			finishWorkshopOrderModalComponent: { notifyCustomerForFinishedOrder },
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()

	dispatch(workshopOrderDetailsStateChange({ pendingUpdatedWorkshopOrder: newOrder }))
	if (
		newOrder.workshopOrderBillingBasis === "executed" &&
		!Number(newOrder.executedDurationMinutes)
	) {
		dispatch(
			finishWorkshopOrderModalStateChange({ showRequired: { executedDurationMinutes: true } })
		)
		return
	}

	let updatedWorkshopOrder = options.skipDenormalize
		? newOrder
		: denormalizeWorkshopOrder(newOrder)

	updatedWorkshopOrder.force_unlink = forceUnlink
	let actionName = "UPDATE_WORKSHOP_ORDER"
	if (options.customAction) {
		actionName = options.customAction
	}

	if (newOrder.newCustomerContractId) {
		updatedWorkshopOrder.new_customer_contract_id = newOrder.newCustomerContractId
	}

	if (newOrder.status === "DONE" && notifyCustomerForFinishedOrder) {
		updatedWorkshopOrder.notify_customer = true
	}
	let init = { method: "PUT", body: JSON.stringify(updatedWorkshopOrder) }
	let url = "/front/affiliates/laundry/laundry_orders/" + updatedWorkshopOrder.id
	dispatch(workshopOrderDetailsStateChange({ showFinishOrderModal: false }))
	return dispatch(simpleFetch(url, init, actionName))
}

export const FETCH_WORKSHOP_ORDER_DETAILS = "FETCH_WORKSHOP_ORDER_DETAILS"
export const FETCH_WORKSHOP_ORDER_DETAILS_REQUEST = "FETCH_WORKSHOP_ORDER_DETAILS_REQUEST"
export const FETCH_WORKSHOP_ORDER_DETAILS_SUCCESS = "FETCH_WORKSHOP_ORDER_DETAILS_SUCCESS"
export const FETCH_WORKSHOP_ORDER_DETAILS_FAILURE = "FETCH_WORKSHOP_ORDER_DETAILS_FAILURE"
export const fetchWorkshopOrderDetails = workshopOrderId => async (dispatch, getState) => {
	let response = await dispatch(fetchWorkshopOrder(workshopOrderId))
	let {
		redData: {
			currentWorkshopOrder: { customer },
		},
	} = getState()
	if (response.actionType === FETCH_WORKSHOP_ORDER_SUCCESS) {
		dispatch(fetchCustomerMessages(customer.id))
	}
}

export const SUBMIT_NEW_AVAILABLE_ORDER_ITEM = "SUBMIT_NEW_AVAILABLE_ORDER_ITEM"
export const SUBMIT_NEW_AVAILABLE_ORDER_ITEM_REQUEST = "SUBMIT_NEW_AVAILABLE_ORDER_ITEM_REQUEST"
export const SUBMIT_NEW_AVAILABLE_ORDER_ITEM_SUCCESS = "SUBMIT_NEW_AVAILABLE_ORDER_ITEM_SUCCESS"
export const SUBMIT_NEW_AVAILABLE_ORDER_ITEM_FAILURE = "SUBMIT_NEW_AVAILABLE_ORDER_ITEM_FAILURE"
export const submitNewAvailableOrderItem = () => (dispatch, getState) => {
	let {
		redComponents: { availableOrderItemCreatorComponent },
	} = getState()
	let init = {
		method: "POST",
		body: JSON.stringify({
			name: availableOrderItemCreatorComponent.shortName,
			long_name: availableOrderItemCreatorComponent.longName,
			price: availableOrderItemCreatorComponent.price.replace(",", "."),
			category: availableOrderItemCreatorComponent.category,
		}),
	}
	let url = "/front/affiliates/laundry/available_laundry_order_items"
	dispatch(simpleFetch(url, init, SUBMIT_NEW_AVAILABLE_ORDER_ITEM))
}

export const DELETE_AVAILABLE_ORDER_ITEM = "DELETE_AVAILABLE_ORDER_ITEM"
export const DELETE_AVAILABLE_ORDER_ITEM_REQUEST = "DELETE_AVAILABLE_ORDER_ITEM_REQUEST"
export const DELETE_AVAILABLE_ORDER_ITEM_SUCCESS = "DELETE_AVAILABLE_ORDER_ITEM_SUCCESS"
export const DELETE_AVAILABLE_ORDER_ITEM_FAILURE = "DELETE_AVAILABLE_ORDER_ITEM_FAILURE"
export const deleteAvailableOrderItem = id => async (dispatch, getState) => {
	let init = { method: "DELETE" }
	let url = "/front/affiliates/laundry/available_laundry_order_items/" + id
	let response = await dispatch(simpleFetch(url, init, DELETE_AVAILABLE_ORDER_ITEM))
	if (response.actionType === DELETE_AVAILABLE_ORDER_ITEM_SUCCESS) {
		dispatch(deletedAvailableOrderItem(id))
	}
}

export const FETCH_WORKSHOP_ORDERS = "FETCH_WORKSHOP_ORDERS"
export const FETCH_WORKSHOP_ORDERS_REQUEST = "FETCH_WORKSHOP_ORDERS_REQUEST"
export const FETCH_WORKSHOP_ORDERS_SUCCESS = "FETCH_WORKSHOP_ORDERS_SUCCESS"
export const FETCH_WORKSHOP_ORDERS_FAILURE = "FETCH_WORKSHOP_ORDERS_FAILURE"
export const fetchWorkshopOrders = ({ computeTotalVouchers = undefined } = {}) => (
	dispatch,
	getState
) => {
	let {
		currentURLQuery: { realQuery },
		redComponents: {
			workshopComponent: { workshopId, pickedDate, reach, deletionDate },
		},
	} = getState()
	if (!workshopId) {
		return
	}
	let init = { method: "GET" }
	let finalQuery = Object.keys(realQuery).reduce((acc, key) => {
		let value = realQuery[key]
		if (!value) return acc
		acc[key] = value
		if (moment.isMoment(value)) {
			acc[key] = value.format("YYYY-MM-DD")
		}
		return acc
	}, {})
	let url =
		"/front/affiliates/laundry/laundry_orders" +
		stringifyQuery({
			...finalQuery,
			workshop_id: workshopId,
			picked_date: pickedDate,
			reach: reach,
			delition_date: deletionDate,
			compute_total_billable_vouchers: computeTotalVouchers,
		})
	return dispatch(simpleFetch(url, init, FETCH_WORKSHOP_ORDERS))
}

export const WORKSHOP_ORDERS_FILTERS = "WORKSHOP_ORDERS_FILTERS"
export const WORKSHOP_ORDERS_FILTERS_REQUEST = "WORKSHOP_ORDERS_FILTERS_REQUEST"
export const WORKSHOP_ORDERS_FILTERS_SUCCESS = "WORKSHOP_ORDERS_FILTERS_SUCCESS"
export const WORKSHOP_ORDERS_FILTERS_FAILURE = "WORKSHOP_ORDERS_FILTERS_FAILURE"
export const fetchWorkshopOrdersFilters = () => (dispatch, getState) => {
	let {
		redComponents: { workshopOrdersFiltersComponent },
	} = getState()
	if (workshopOrdersFiltersComponent.fetched) return
	let init = { method: "GET" }
	let url = "/front/affiliates/laundry_orders_filters"
	dispatch(simpleFetch(url, init, WORKSHOP_ORDERS_FILTERS))
}

const SUBMIT_WORKSHOP_HOUR = "SUBMIT_WORKSHOP_HOUR"
export const SUBMIT_WORKSHOP_HOUR_REQUEST = "SUBMIT_WORKSHOP_HOUR_REQUEST"
export const SUBMIT_WORKSHOP_HOUR_SUCCESS = "SUBMIT_WORKSHOP_HOUR_SUCCESS"
export const SUBMIT_WORKSHOP_HOUR_FAILURE = "SUBMIT_WORKSHOP_HOUR_FAILURE"
export const submitWorkshopHour = () => (dispatch, getState) => {
	let {
		redComponents: {
			workshopHourModalComponent,
			workshopHourModalComponent: {
				showRequired,
				startDate,
				start,
				end,
				dayPeriod,
				workshopId,
				chosenWorker: { affiliateWorkerId },
			},
		},
	} = getState()

	let toCheck = ["startDate", "start", "end", "dayPeriod", "workshopId"]
	let valid = true
	let toRequire = []

	toCheck.forEach(entry => {
		if (!workshopHourModalComponent[entry]) {
			valid = false
			toRequire.push(entry)
		}
	})
	if (!valid) {
		let newRequired = { ...showRequired }
		toRequire.forEach(entry => {
			newRequired[entry] = true
		})
		dispatch(workshopHourModalStateChange({ showRequired: newRequired }))
		return { actionType: undefined, status: undefined }
	}

	let url = "/front/affiliates/worker_contracts/0/other_activities"
	let init = {
		method: "POST",
		body: JSON.stringify({
			affiliate_worker_id: affiliateWorkerId,
			start_date: startDate.format("YYYY-MM-DD"),
			end_date: dayPeriod === "0" ? startDate.format() : null,
			start_time: start.format("HH:mm"),
			end_time: end.format("HH:mm"),
			day_period: dayPeriod,
			is_workshop_hour: true,
			workshop_id: workshopId,
		}),
	}
	return dispatch(simpleFetch(url, init, SUBMIT_WORKSHOP_HOUR))
}

const SUBMIT_EDIT_WORKSHOP_HOUR = "SUBMIT_EDIT_WORKSHOP_HOUR"
export const SUBMIT_EDIT_WORKSHOP_HOUR_REQUEST = "SUBMIT_EDIT_WORKSHOP_HOUR_REQUEST"
export const SUBMIT_EDIT_WORKSHOP_HOUR_SUCCESS = "SUBMIT_EDIT_WORKSHOP_HOUR_SUCCESS"
export const SUBMIT_EDIT_WORKSHOP_HOUR_FAILURE = "SUBMIT_EDIT_WORKSHOP_HOUR_FAILURE"

export const submitEditWorkshopHour = id => (dispatch, getState) => {
	let {
		redComponents: {
			workshopHourModalComponent,
			workshopHourModalComponent: {
				dayPeriod,
				recurrence,
				showRequired,
				start,
				end,
				workshopId,
				chosenWorker: { affiliateWorkerId },
			},
		},
	} = getState()

	let toCheck = ["startDate", "start", "end"]
	let valid = true
	let toRequire = []

	toCheck.forEach(entry => {
		if (!workshopHourModalComponent[entry]) {
			valid = false
			toRequire.push(entry)
		}
	})
	if (!valid) {
		let newRequired = { ...showRequired }
		toRequire.forEach(entry => {
			newRequired[entry] = true
		})
		dispatch(workshopHourModalStateChange({ showRequired: newRequired }))
		return { actionType: undefined, status: undefined }
	}
	let url = `/front/affiliates/worker_contracts/0/other_activities/${id}`
	let init = {
		method: "PUT",
		body: JSON.stringify({
			start_time: start.format("HH:mm"),
			execution_date: start.format("DD-MM-YYYY"),
			end_time: end.format("HH:mm"),
			affiliate_worker_id: affiliateWorkerId,
			day_period: dayPeriod,
			recurrence: recurrence,
			is_workshop_hour: true,
			workshop_id: workshopId,
		}),
	}
	return dispatch(simpleFetch(url, init, SUBMIT_EDIT_WORKSHOP_HOUR))
}

const FETCH_ORDER_QUOTE = "FETCH_ORDER_QUOTE"
export const FETCH_ORDER_QUOTE_REQUEST = "FETCH_ORDER_QUOTE_REQUEST"
export const FETCH_ORDER_QUOTE_SUCCESS = "FETCH_ORDER_QUOTE_SUCCESS"
export const FETCH_ORDER_QUOTE_FAILURE = "FETCH_ORDER_QUOTE_FAILURE"
export const fetchOrderQuote = (
	chosenOrderItems,
	chosenCustomer,
	executedDurationMinutes = null,
	workshopOrderBillingBasis = null
) => async (dispatch, getState) => {
	let url = "/front/affiliates/laundry/compute_laundry_orders_quote/"
	let init = {
		method: "POST",
		body: JSON.stringify({
			items: chosenOrderItems.map(item => ({
				id: item.id,
				av_item_id: item.avItemID,
				quantity: item.quantity,
			})),
			customer_contract_id: chosenCustomer.id,
			executed_duration_minutes: executedDurationMinutes,
			workshop_order_duration_choice: workshopOrderBillingBasis,
		}),
	}
	let { actionType } = await dispatch(simpleFetch(url, init, FETCH_ORDER_QUOTE))
	if (actionType === FETCH_ORDER_QUOTE_SUCCESS) {
		let {
			redComponents: { newWorkshopOrderComponent },
		} = getState()
		let readyArr = validateNewOrderState(newWorkshopOrderComponent)
		dispatch(
			newWorkshopOrderStateChange({
				readyForStep: readyArr.filter(entry => entry).length + 1,
			})
		)
	}
}

const SEARCH_WORKSHOP_HOURS = "SEARCH_WORKSHOP_HOURS"
export const SEARCH_WORKSHOP_HOURS_REQUEST = "SEARCH_WORKSHOP_HOURS_REQUEST"
export const SEARCH_WORKSHOP_HOURS_SUCCESS = "SEARCH_WORKSHOP_HOURS_SUCCESS"
export const SEARCH_WORKSHOP_HOURS_FAILURE = "SEARCH_WORKSHOP_HOURS_FAILURE"
export const searchWorkshopHours = ({ workshopId, duration }) => (dispatch, getState) => {
	let query = { laundry_order_duration: duration, workshop_id: workshopId }
	let url = "/front/affiliates/laundry/search_workshop_hours/" + stringifyQuery(query)
	return dispatch(simpleFetch(url, { method: "GET" }, SEARCH_WORKSHOP_HOURS))
}

const FETCH_EARLIEST_PICK_UP_DATE = "FETCH_EARLIEST_PICK_UP_DATE"
export const FETCH_EARLIEST_PICK_UP_DATE_REQUEST = "FETCH_EARLIEST_PICK_UP_DATE_REQUEST"
export const FETCH_EARLIEST_PICK_UP_DATE_SUCCESS = "FETCH_EARLIEST_PICK_UP_DATE_SUCCESS"
export const FETCH_EARLIEST_PICK_UP_DATE_FAILURE = "FETCH_EARLIEST_PICK_UP_DATE_FAILURE"
export const fetchEarliestPickUpDate = ({ workshopId, duration, specificAffiliateWorkerId }) => (
	dispatch,
	getState
) => {
	let url = "/front/affiliates/laundry/search_workshop_hours/find_pick_up_date"
	let init = {
		method: "POST",
		body: JSON.stringify({
			laundry_order_duration: duration,
			workshop_id: workshopId,
			specific_affiliate_worker_id: specificAffiliateWorkerId,
		}),
	}
	return dispatch(simpleFetch(url, init, FETCH_EARLIEST_PICK_UP_DATE))
}

export const processNewWorkshopOrderChange = changes => async (dispatch, getState) => {
	let {
		redComponents: { newWorkshopOrderComponent },
	} = getState()
	let newState = { ...newWorkshopOrderComponent, ...changes }
	let readyArr = validateNewOrderState(newState)
	dispatch(
		newWorkshopOrderStateChange({
			...changes,
			readyForStep: readyArr.filter(entry => entry).length + 1,
		})
	)
	if (
		newWorkshopOrderComponent.currentStep !== newState.currentStep &&
		newState.currentStep === 3
	) {
		let { actionType, data } = await dispatch(
			fetchEarliestPickUpDate({
				workshopId: newWorkshopOrderComponent.workshopId,
				duration: newWorkshopOrderComponent.currentQuote.estimatedDuration,
				specificAffiliateWorkerId: newWorkshopOrderComponent.chosenWorker.affiliateWorkerId,
			})
		)
		if (actionType === FETCH_EARLIEST_PICK_UP_DATE_SUCCESS) {
			let {
				redComponents: { newWorkshopOrderComponent: newComponentData },
			} = getState()
			let newState = {
				...newComponentData,
				earliestPossiblePickUpDate: data.earliest_possible_pick_up_date,
			}
			let readyArr = validateNewOrderState(newState)
			dispatch(
				newWorkshopOrderStateChange({
					...newState,
					readyForStep: readyArr.filter(entry => entry).length + 1,
				})
			)
		}
	}
}

export const SUBMIT_WORKSHOP_ORDER = "SUBMIT_WORKSHOP_ORDER"
export const SUBMIT_WORKSHOP_ORDER_REQUEST = "SUBMIT_WORKSHOP_ORDER_REQUEST"
export const SUBMIT_WORKSHOP_ORDER_SUCCESS = "SUBMIT_WORKSHOP_ORDER_SUCCESS"
export const SUBMIT_WORKSHOP_ORDER_FAILURE = "SUBMIT_WORKSHOP_ORDER_FAILURE"
export const submitWorkshopOrder = () => (dispatch, getState) => {
	let {
		redComponents: { newWorkshopOrderComponent },
	} = getState()
	let url = "/front/affiliates/laundry/laundry_orders"
	let toCheck = ["svType", "pickUpDate", "workshopId"]
	let valid = true
	toCheck.forEach(entry => {
		if (!newWorkshopOrderComponent[entry]) {
			valid = false
		}
	})
	if (!valid) {
		return
	}
	let init = {
		method: "POST",
		body: JSON.stringify({
			priority: newWorkshopOrderComponent.priorityOrder || 0,
			customer_contract_id: newWorkshopOrderComponent.chosenCustomer.id,
			sv_type: newWorkshopOrderComponent.svType, //newWorkshopOrderComponent.priorityOrder,
			scheduled_pick_up_date: newWorkshopOrderComponent.pickUpDate.format("YYYY-MM-DD"),
			items: newWorkshopOrderComponent.chosenOrderItems.map(entry => ({
				quantity: entry.quantity,
				av_item_id: entry.avItemID,
			})),
			workshop_id: newWorkshopOrderComponent.workshopId,
			specific_affiliate_worker_id: newWorkshopOrderComponent.chosenWorker.affiliateWorkerId,
			billable_hours_override: newWorkshopOrderComponent.billableHoursOverride,
		}),
	}
	return dispatch(simpleFetch(url, init, SUBMIT_WORKSHOP_ORDER))
}

export const SUBMIT_PAST_WORKSHOP_ORDER = "SUBMIT_PAST_WORKSHOP_ORDER"
export const SUBMIT_PAST_WORKSHOP_ORDER_REQUEST = "SUBMIT_PAST_WORKSHOP_ORDER_REQUEST"
export const SUBMIT_PAST_WORKSHOP_ORDER_SUCCESS = "SUBMIT_PAST_WORKSHOP_ORDER_SUCCESS"
export const SUBMIT_PAST_WORKSHOP_ORDER_FAILURE = "SUBMIT_PAST_WORKSHOP_ORDER_FAILURE"
export const submitPastWorkshopOrder = (contractID, week) => async (dispatch, getState) => {
	let {
		redComponents: { missingLaundryOrderModalComponent },
	} = getState()
	let url = "/front/affiliates/laundry/laundry_orders/create_past_laundry_order"
	let init = {
		method: "POST",
		body: JSON.stringify({
			customer_contract_id: missingLaundryOrderModalComponent.chosenCustomer.id,
			sv_type: missingLaundryOrderModalComponent.svType, //missingLaundryOrderModalComponent.priorityOrder,
			workshop_id: missingLaundryOrderModalComponent.workshopId,
			worker_contract_id: missingLaundryOrderModalComponent.workerContractId,
			execution_date: missingLaundryOrderModalComponent.executionDate,
			executed_duration_minutes: missingLaundryOrderModalComponent.executedDurationMinutes,
			workshop_shift_id: missingLaundryOrderModalComponent.workshopShiftId,
			billable_hours_override: missingLaundryOrderModalComponent.billableHoursOverride,
		}),
	}
	let { actionType } = await dispatch(simpleFetch(url, init, SUBMIT_PAST_WORKSHOP_ORDER))
	if (actionType === SUBMIT_PAST_WORKSHOP_ORDER_SUCCESS) {
		dispatch(agendaStateChange({ showMissingLaundryOrderModal: false }))
		dispatch(resetMissingLaundryOrderModal())
		let currWeek = moment(week, "YYYY-MM-DD")
		dispatch(refetchAgendaLines([contractID], currWeek.format()))
	}
}

export const SUBMIT_DELETE_WORKSHOP_ORDER = "SUBMIT_DELETE_WORKSHOP_ORDER"
export const SUBMIT_DELETE_WORKSHOP_ORDER_REQUEST = "SUBMIT_DELETE_WORKSHOP_ORDER_REQUEST"
export const SUBMIT_DELETE_WORKSHOP_ORDER_SUCCESS = "SUBMIT_DELETE_WORKSHOP_ORDER_SUCCESS"
export const SUBMIT_DELETE_WORKSHOP_ORDER_FAILURE = "SUBMIT_DELETE_WORKSHOP_ORDER_FAILURE"
export const submitDeleteLaundryOrder = id => (dispatch, getState) => {
	let {
		redComponents: {
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()
	let url = "/front/affiliates/laundry/laundry_orders/" + id
	let init = { method: "DELETE", body: JSON.stringify({ force_unlink: forceUnlink }) }
	return dispatch(simpleFetch(url, init, SUBMIT_DELETE_WORKSHOP_ORDER))
}

export const submitWorkshopHourFromNewOrder = () => async (dispatch, getState) => {
	let { actionType, status } = await dispatch(submitWorkshopHour())
	if (status === 412) {
		dispatch(workshopHourModalStateChange({ warning: "workshop_hour_cant_be_created_412" }))
	} else if (actionType === SUBMIT_WORKSHOP_HOUR_SUCCESS) {
		let {
			redComponents: { newWorkshopOrderComponent },
		} = getState()
		dispatch(newWorkshopOrderStateChange({ showWorkshopHourModal: false }))
		let { actionType: fepuActionType, data: fepuData } = await dispatch(
			fetchEarliestPickUpDate({
				workshopId: newWorkshopOrderComponent.workshopId,
				duration: newWorkshopOrderComponent.currentQuote.estimatedDuration,
				specificAffiliateWorkerId: newWorkshopOrderComponent.chosenWorker.affiliateWorkerId,
			})
		)
		if (fepuActionType === FETCH_EARLIEST_PICK_UP_DATE_SUCCESS) {
			let {
				redComponents: { newWorkshopOrderComponent: newComponentData },
			} = getState()
			let newState = {
				...newComponentData,
				earliestPossiblePickUpDate: fepuData.earliest_possible_pick_up_date,
			}
			let readyArr = validateNewOrderState(newState)
			dispatch(
				newWorkshopOrderStateChange({
					...newState,
					readyForStep: readyArr.filter(entry => entry).length + 1,
				})
			)
		}
	}
}

export const SUBMIT_NEW_WORKSHOP = "SUBMIT_NEW_WORKSHOP"
export const SUBMIT_NEW_WORKSHOP_REQUEST = "SUBMIT_NEW_WORKSHOP_REQUEST"
export const SUBMIT_NEW_WORKSHOP_SUCCESS = "SUBMIT_NEW_WORKSHOP_SUCCESS"
export const SUBMIT_NEW_WORKSHOP_FAILURE = "SUBMIT_NEW_WORKSHOP_FAILURE"
export const submitNewWorkshop = id => (dispatch, getState) => {
	let {
		redComponents: {
			workshopSettingsComponent: { workshopName },
		},
	} = getState()
	let url = "/front/affiliates/laundry/workshops/"
	let init = { method: "POST", body: JSON.stringify({ workshop_name: workshopName }) }
	dispatch(simpleFetch(url, init, SUBMIT_NEW_WORKSHOP))
}

export const UPDATE_WORKSHOP = "UPDATE_WORKSHOP"
export const UPDATE_WORKSHOP_REQUEST = "UPDATE_WORKSHOP_REQUEST"
export const UPDATE_WORKSHOP_SUCCESS = "UPDATE_WORKSHOP_SUCCESS"
export const UPDATE_WORKSHOP_FAILURE = "UPDATE_WORKSHOP_FAILURE"
export const updateWorkshop = data => (dispatch, getState) => {
	let url = "/front/affiliates/laundry/workshops/" + data.id
	let init = { method: "PUT", body: JSON.stringify(data) }
	dispatch(simpleFetch(url, init, UPDATE_WORKSHOP))
}

export const UPDATE_WORKSHOP_ADDRESS = "UPDATE_WORKSHOP_ADDRESS"
export const UPDATE_WORKSHOP_ADDRESS_REQUEST = "UPDATE_WORKSHOP_ADDRESS_REQUEST"
export const UPDATE_WORKSHOP_ADDRESS_SUCCESS = "UPDATE_WORKSHOP_ADDRESS_SUCCESS"
export const UPDATE_WORKSHOP_ADDRESS_FAILURE = "UPDATE_WORKSHOP_ADDRESS_FAILURE"
export const updateWorkshopAddress = data => (dispatch, getState) => {
	let url = "/front/affiliates/laundry/workshops/" + data.id + "/update_address"
	let init = { method: "PUT", body: JSON.stringify(data) }
	dispatch(simpleFetch(url, init, UPDATE_WORKSHOP_ADDRESS))
}

export const processWorkshopChange = ({ newWorkshopId }) => async (dispatch, getState) => {
	await dispatch(workshopStateChange({ workshopId: newWorkshopId }))
	dispatch(fetchWorkshopOrders())
	dispatch(fetchDayReport())
}

export const REMOVE_WORKSHOP_ORDER_PSV = "REMOVE_WORKSHOP_ORDER_PSV"
export const REMOVE_WORKSHOP_ORDER_PSV_REQUEST = "REMOVE_WORKSHOP_ORDER_PSV_REQUEST"
export const REMOVE_WORKSHOP_ORDER_PSV_SUCCESS = "REMOVE_WORKSHOP_ORDER_PSV_SUCCESS"
export const REMOVE_WORKSHOP_ORDER_PSV_FAILURE = "REMOVE_WORKSHOP_ORDER_PSV_FAILURE"
export const destroyPSV = psvId => (dispatch, getState) => {
	let init = {
		method: "POST",
		body: JSON.stringify({ id: psvId }),
	}
	let url = `/front/affiliates/paper_service_vouchers/unlink`
	dispatch(simpleFetch(url, init, REMOVE_WORKSHOP_ORDER_PSV))
}

export const afterPSVSubmission = () => (dispatch, getState) => {
	let {
		redData: {
			currentWorkshopOrder: { id },
		},
	} = getState()
	dispatch(fetchWorkshopOrderDetails(id))
	dispatch(workshopOrderDetailsStateChange({ showAddPSVModal: false }))
}

export const setGlobalWorkshopId = () => async (dispatch, getState) => {
	let {
		redData: {
			currentAffiliate: { workshops },
		},
	} = getState()
	if (workshops.length === 1) {
		dispatch(workshopStateChange({ workshopId: workshops[0].id }))
		localStorage.setItem("pootsy-last-chosen-workshop", workshops[0].id)
	} else {
		let lastChosenWorkshop = localStorage.getItem("pootsy-last-chosen-workshop")
		let ids = workshops.map(workshop => workshop.id)
		if ((!lastChosenWorkshop || !ids.includes(lastChosenWorkshop)) && workshops.length > 0) {
			dispatch(workshopStateChange({ workshopId: workshops[0].id }))
		} else {
			dispatch(workshopStateChange({ workshopId: lastChosenWorkshop }))
		}
	}
}

export const WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT = "WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT"
export const WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT_REQUEST =
	"WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT_REQUEST"
export const WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT_SUCCESS =
	"WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT_SUCCESS"
export const WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT_FAILURE =
	"WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT_FAILURE"
export const submitReSchedulingAttempt = ({ dryRun = true }) => async (dispatch, getState) => {
	let {
		redComponents: {
			workshopOrdersIssuesComponent: { stagedForReScheduling },
			workshopComponent: { workshopId },
		},
	} = getState()
	let url = "/front/affiliates/laundry/laundry_orders/reschedule"
	let init = {
		method: "POST",
		body: JSON.stringify({
			order_ids_staged_for_rescheduling: stagedForReScheduling,
			dry_run: dryRun,
			workshop_id: workshopId,
		}),
	}
	let { actionType, error } = await dispatch(
		simpleFetch(url, init, WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT)
	)
	if (actionType === WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT_SUCCESS) {
		let changes = {
			showReschedulingConfirmationModal: dryRun,
			disableReSchedulingButton: false,
			dryRunSuccess: dryRun,
			errorMessage: "",
		}
		if (!dryRun) changes.stagedForReScheduling = [] // reset staged items if it wasn't a dryrun
		dispatch(workshopOrdersIssuesStateChange(changes))
		if (!dryRun) dispatch(fetchWorkshopOrders())
	} else if (actionType === WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT_FAILURE) {
		dispatch(
			workshopOrdersIssuesStateChange({
				showReschedulingConfirmationModal: true,
				errorObject: error.json.error_object,
				errorMessage: error.json.error_message,
				disableReSchedulingButton: true,
				dryRunSuccess: false,
			})
		)
		dispatch(acknowledgeErrors())
	}
}
