import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as actions from "../actions"

import {
	PageLayout,
	NavLinkArrowBack,
	StepButton,
	PootsyEvolvingData,
	LabeledDateTime,
	PootsySubHeading,
	PootsyButton,
} from "./FunctionalDesign"
import { PootsyCheckboxInput, PootsySelectInput, PootsyTextInput } from "./FunctionalInputs"
import NewCustomerModal from "./NewCustomerModal"
import CustomerSelector from "./CustomerSelector"
import { WorkshopHourModal } from "./WorkshopHourModal"
import WorkshopOrderQuoteDisplayer from "./WorkshopOrderQuoteDisplayer"
import AvailableWorkshopOrderItemInput from "./AvailableWorkshopOrderItemInput"
import { groupBy } from "../helpers/groupBy"
import SearchWorker from "./SearchWorker"
import InputsChangeHandler from "./InputsChangeHandler"

class NewWorkshopOrder extends Component {
	state = {
		queuedCall: "",
	}
	componentDidMount = () => {
		let { currentAffiliate, dispatch, workshopComponent } = this.props
		dispatch(actions.fetchAvailableOrderItemsList())
		// dispatch(actions.fetchMaxShift())
		if (currentAffiliate.workshops.length === 1) {
			this.handleChanges({ workshopId: currentAffiliate.workshops[0].id })
		} else {
			this.handleChanges({ workshopId: workshopComponent.workshopId })
		}
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetNewWorkshopOrderState())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.processNewWorkshopOrderChange(changes))
	}
	toggleNewCustomerModal = () => {
		let {
			component: { showNewCustomerModal },
		} = this.props
		this.handleChanges({ showNewCustomerModal: !showNewCustomerModal })
	}
	toggleWorkshopHourModal = () => {
		this.handleChanges({ showWorkshopHourModal: !this.props.component.showWorkshopHourModal })
	}
	goToStep = step => {
		let {
			component: { readyForStep },
		} = this.props
		if (step > readyForStep) return
		this.handleChanges({ currentStep: step })
	}
	handleSearchWorker = workerName => {
		this.handleChanges({ chosenWorker: { name: workerName, sodexo: "" } })
	}
	handleWorkerClick = worker => {
		let newChosenWorker = {
			name: worker.attributes.display_name,
			sodexo: worker.attributes.sodexo_reference,
			affiliateWorkerId: Number(worker.id),
		}
		this.handleChanges({ chosenWorker: newChosenWorker })
		this.props.dispatch(actions.resetWorkerSuggestions())
	}
	handleCustomerClick = customer => {
		this.handleChanges({
			svType: customer.attributes.voucher_type_preference || "",
			chosenCustomer: {
				id: Number(customer.id),
				name: customer.attributes.display_name,
				sodexo: customer.attributes.sodexo_reference,
			},
			billableHoursOverride: undefined,
		})
		this.props.dispatch(actions.resetCustomerSuggestions())
	}
	handleOrderItemChange = ({ avItemID, quantity }) => {
		let {
			dispatch,
			component: { chosenOrderItems, chosenCustomer },
		} = this.props
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall
		let existingObj = chosenOrderItems.find(entry => entry.avItemID === avItemID)
		let newChosenOrderItems = []
		if (existingObj) {
			newChosenOrderItems = chosenOrderItems.map(entry =>
				entry.avItemID === avItemID ? { avItemID, quantity } : entry
			)
		} else {
			newChosenOrderItems = [...chosenOrderItems, { avItemID, quantity }]
		}
		newChosenOrderItems = newChosenOrderItems.filter(entry => entry.quantity !== 0)
		this.handleChanges({
			chosenOrderItems: newChosenOrderItems,
			billableHoursOverride: undefined,
		})
		if (newChosenOrderItems.length === 0) {
			return
		}
		futureCall = setTimeout(() => {
			dispatch(actions.fetchOrderQuote(newChosenOrderItems, chosenCustomer))
		}, 1000)
		this.setState({ queuedCall: futureCall })
	}
	prefillbillableHoursOverride = () => {
		let {
			// dispatch,
			component: { currentQuote },
		} = this.props
		if (!currentQuote.customerBalance) {
			return
		}
		let balance = currentQuote.customerBalance.current_minutes_balance
		let currentMinutesEstimation = currentQuote.details.find(
			entry => entry.categoryName === "SERVICE_VOUCHER"
		).totalDetails.estimated_billable_minutes

		let resolveBalance = Math.abs(Math.min(0, balance - currentMinutesEstimation))
		this.handleChanges({ billableHoursOverride: Math.ceil(resolveBalance / 60) })
	}
	submitWorkshopHour = () => {
		this.props.dispatch(actions.submitWorkshopHourFromNewOrder())
	}
	submitWorkshopOrder = () => {
		this.props.dispatch(actions.submitWorkshopOrder())
	}
	render() {
		let { t } = this.context
		let {
			availableOrderItems,
			currentAffiliate,
			component: {
				showWorkshopHourModal,
				showNewCustomerModal,
				currentStep,
				readyForStep,
				dropOffDate,
				chosenWorker,
				chosenCustomer,
				priorityOrder,
				pickUpDate,
				chosenOrderItems,
				currentQuote,
				earliestPossiblePickUpDate,
				svType,
				workshopId,
				fetchingQuote,
				billableHoursOverride,
			},
			maxShiftLength,
		} = this.props
		let currentDate = new Date()

		let pageHeaderLeft = (
			<div className="page-header-left">
				<NavLinkArrowBack to="/workshop/com-center/today" />
				<h1 className="page-title">{t("new_workshop_order")}</h1>
			</div>
		)
		let customerImbalanced = !!currentQuote.customerBalance // &&
		// currentQuote.customerBalance.current_minutes_balance !== 0

		let orderWarnings = []
		//console.log(maxShiftLength.maxLength)
		let vouchers = currentQuote.details.find(entry => entry.categoryName === "SERVICE_VOUCHER")
		let order_minutes = null
		// console.log(vouchers)
		if (vouchers) {
			order_minutes = vouchers.totalDetails.estimated_billable_minutes
			// console.log(vouchers.totalDetails.estimated_billable_minutes)
		}
		// console.log(order_minutes)
		// console.log(maxShiftLength)
		if (order_minutes && maxShiftLength && order_minutes > maxShiftLength.maxLength) {
			orderWarnings.push("warning-order-exceeds-limit")
		}
		let dropOffDateLaterThanPickUp =
			dropOffDate && pickUpDate && pickUpDate.isBefore(dropOffDate)
		if (dropOffDateLaterThanPickUp) {
			orderWarnings.push("warning-order-drop-off-date-warning")
		}
		return (
			<PageLayout customClass="NewWorkshopOrder" headerLeft={pageHeaderLeft}>
				{showWorkshopHourModal && (
					<WorkshopHourModal
						showWorkerPicker={true}
						showStartDatePicker={true}
						closeModal={this.toggleWorkshopHourModal}
						submit={this.submitWorkshopHour}
						t={t}
					/>
				)}
				{showNewCustomerModal && (
					<NewCustomerModal
						fromPage="newWorkshopOrder"
						onCancel={this.toggleNewCustomerModal}
					/>
				)}
				<InputsChangeHandler
					customClass="NewWorkshopOrder-content"
					onChange={this.handleChanges}
				>
					<div className="left-part">
						<div className="step-indicator">
							<StepButton
								active={currentStep === 1}
								disabled={false}
								onClick={() => this.goToStep(1)}
								text="1"
							/>
							<StepButton
								active={currentStep === 2}
								disabled={readyForStep < 2}
								onClick={() => this.goToStep(2)}
								text="2"
							/>
							<StepButton
								active={currentStep === 3}
								disabled={readyForStep < 3}
								onClick={() => this.goToStep(3)}
								text="3"
							/>
						</div>
						<div className="steps-container">
							<div className={"slider step-" + currentStep}>
								<div className="step step-1">
									<div className="step-header">
										<PootsySubHeading text={t("customer")} />
										<PootsyButton
											text={t("next")}
											onClick={() => this.goToStep(2)}
											disabled={readyForStep < 2}
										/>
									</div>
									<div className="top-part">
										<PootsySelectInput
											name="workshopId"
											defaultText={t("workshop")}
											selected={workshopId}
											options={currentAffiliate.workshops.map(entry => ({
												label: entry.workshopName,
												value: entry.id,
											}))}
										/>
										<CustomerSelector
											chosenCustomer={chosenCustomer}
											svType={svType}
											handleCustomerClick={this.handleCustomerClick}
										/>
										<SearchWorker
											onChange={this.handleSearchWorker}
											onClick={this.handleWorkerClick}
											value={chosenWorker.name}
										/>
										<PootsyButton
											text={t("create_new_customer")}
											onClick={this.toggleNewCustomerModal}
										/>
									</div>
								</div>
								<div className="step step-2">
									<div className="step-header">
										<PootsySubHeading text={t("quotation")} />
										<PootsyButton
											text={t("next")}
											onClick={() => this.goToStep(3)}
											disabled={readyForStep < 3}
										/>
									</div>
									<div className="order-items">
										{groupBy(availableOrderItems, "categoryName").map(
											avOrderItems => {
												let category = avOrderItems[0].categoryName
												return (
													<div
														key={category}
														className="order-item-category"
													>
														<PootsySubHeading text={t(category)} />
														<div className="items">
															{avOrderItems.map(avOrderItem => {
																let chosen = chosenOrderItems.find(
																	entry =>
																		entry.avItemID ===
																		avOrderItem.id
																)
																let quantity = chosen
																	? chosen.quantity
																	: ""
																return (
																	<AvailableWorkshopOrderItemInput
																		key={avOrderItem.id}
																		orderItem={avOrderItem}
																		quantity={quantity}
																		onChange={
																			this
																				.handleOrderItemChange
																		}
																	/>
																)
															})}
														</div>
													</div>
												)
											}
										)}
									</div>
									<WorkshopOrderQuoteDisplayer
										quote={currentQuote}
										handleOrderItemChange={this.handleOrderItemChange}
										loading={fetchingQuote}
									/>
									{customerImbalanced && (
										<div className="customer-imbalance">
											<div className="text">
												{currentQuote.customerBalance
													.current_minutes_balance > 0
													? t("workskhop_customer_has_positive_balance")
													: t("workskhop_customer_has_negative_balance")}
											</div>
											<div className="inputs">
												<PootsyTextInput
													label={t("billable_hours_override")}
													name="billableHoursOverride"
													value={billableHoursOverride}
													type="number"
													min="0"
												/>
												<PootsyButton
													size="small"
													text={t(
														"workshop_prefill_billable_hours_override"
													)}
													onClick={this.prefillbillableHoursOverride}
												/>
											</div>
										</div>
									)}
								</div>
								<div className="step step-3">
									<div className="step-header">
										<PootsySubHeading text={t("quotation")} />
										<PootsyButton
											text={t("create_workshop_order")}
											onClick={this.submitWorkshopOrder}
											disabled={dropOffDateLaterThanPickUp}
										/>
									</div>
									<div className="top-row">
										<LabeledDateTime
											label={t("drop_off_date")}
											showTimePicker={false}
											datePickerProps={{
												name: "dropOffDate",
												value: dropOffDate,
												onChange: this.handleChanges,
												calendarStyle: { top: "150%", left: 0 },
												disableDay: day =>
													!currentDate || day.isBefore(currentDate),
											}}
										/>
										<PootsyCheckboxInput
											name="priorityOrder"
											label={t("urgent_order")}
											checked={priorityOrder}
										/>
									</div>
									<div className="process-dates">
										<LabeledDateTime
											label={t("pick_up_date")}
											showTimePicker={false}
											datePickerProps={{
												name: "pickUpDate",
												value: pickUpDate,
												alwaysOpened: true,
												disableAutoFocus: true,

												disableDay: day =>
													!earliestPossiblePickUpDate ||
													day.isBefore(earliestPossiblePickUpDate),
												onChange: this.handleChanges,
												// onMonthChange: this.handleDisplayedMonthChange,
											}}
										/>
										<PootsyButton
											text={t("click_here_to_add_workshop_hours")}
											onClick={this.toggleWorkshopHourModal}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="right-part">
						<PootsyEvolvingData
							title={t("summary")}
							data={[
								{
									label: t("estimated_price"),
									value: currentQuote.estimatedPrice,
									show: currentQuote.estimatedPrice,
									style: { marginBottom: 20 },
								},
								{
									label: t("drop_off_date"),
									value: dropOffDate ? dropOffDate.format("DD/MM/YYYY") : "",
									show: true,
								},
								{
									label: t("pick_up_date"),
									value: pickUpDate ? pickUpDate.format("DD/MM/YYYY") : "",
									show: true,
								},
								{
									label: t("urgent_order"),
									value: priorityOrder ? t("yes") : t("no"),
									show: true,
									style: { marginBottom: 20 },
								},
								{
									label: t("customer"),
									value: chosenCustomer.name,
									show: chosenCustomer.name,
								},
								{
									label: t("customer_sodexo_reference"),
									value: chosenCustomer.sodexo || t("unknown"),
									show: true,
								},
								{ label: t("voucher_type"), value: t(svType), show: true },
								...currentQuote.details.reduce((acc, entry) => {
									Object.entries(entry.totalDetails).forEach(([key, value]) => {
										if (
											[
												"estimated_balance_minutes_used",
												"estimated_minutes_to_be_reported",
												"estimated_billable_minutes",
												"estimated_billable_service_voucher",
											].includes(key)
										) {
											acc.push({ label: t(key), value: value, show: true })
										}
									})
									return acc
								}, []),
								{
									label: t("customer_workshop_total_minutes"),
									value: currentQuote.customerBalance
										? `${currentQuote.customerBalance.order_minutes_total} (${
												currentQuote.customerBalance
													.total_due_service_vouchers
										  } ${t("service_vouchers")})`
										: "",
									show: currentQuote.customerBalance,
								},
								{
									label: t("workshop_service_vouchers_total_paid"),
									value: currentQuote.customerBalance
										? currentQuote.customerBalance.service_vouchers_total
										: "",
									show: currentQuote.customerBalance,
								},
								{
									label: t(
										currentQuote.customerBalance &&
											currentQuote.customerBalance.current_minutes_balance < 0
											? "customer_workshop_voucher_debit"
											: "customer_workshop_voucher_credit"
									),
									value: currentQuote.customerBalance
										? currentQuote.customerBalance.current_minutes_balance
										: "",
									show: currentQuote.customerBalance,
								},
								{
									label: t("billable_hours_override"),
									value: billableHoursOverride,
									show: billableHoursOverride,
								},
							]}
						/>
						{orderWarnings.length > 0 && (
							<div className="workshop-warnings">
								<PootsySubHeading text={t("warning")} />
								{orderWarnings.map((e, i) => (
									<div
										key={i}
										className="evolving-value"
										style={{ marginBottom: 20 }}
									>
										<div className="label">{t(e)}</div>
									</div>
								))}
							</div>
						)}
					</div>
				</InputsChangeHandler>
			</PageLayout>
		)
	}
}

NewWorkshopOrder.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.newWorkshopOrderComponent,
	workshopComponent: state.redComponents.workshopComponent,
	maxShiftLength: state.redComponents.workshopWorkerShiftsComponent,
	workerSuggestions: state.redData.workerSuggestions,
	customerSuggestions: state.redData.customerSuggestions,
	availableOrderItems: state.redData.avOrderItemsList,
	currentAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(NewWorkshopOrder)
